import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import { injectIntl } from 'react-intl'

const ContactUs = ({ intl }) => (
  <section id="contact" className="section is-medium has-background-primary">
    <div className="container">
      <h2>{intl.formatMessage({ id: 'contactUs' })}</h2>
      <div className="columns">
        <div className="column is-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2000.125507043225!2d10.739026416095992!3d59.91346438186706!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416f2f6793eb19%3A0x2156ee2f7bb6c864!2sKristiania+Management+AS!5e0!3m2!1sno!2sno!4v1541855668289"
            width="100%"
            title={intl.formatMessage({ id: 'mapOverKM' })}
            frameBorder="0"
            style={{ border: 0, height: '450px' }}
            allowFullScreen
          />
        </div>
        <div id="contact-info" className="column">
          <h3>{intl.formatMessage({ id: 'address' })}</h3>
          <p>Karl Johans gate 25, 0159 Oslo</p>
          <h3>{intl.formatMessage({ id: 'phone' })}</h3>
          <a className="link" href="tel:+4746800003">
            +47 468 00 003
          </a>
          <h3>{intl.formatMessage({ id: 'email' })}</h3>
          <a className="link" href="mailto:info@kristianiamanagement.no">
            info@kristianiamanagement.no
          </a>
          <div className="is-hidden-mobile so-me">
            <ul>
              <IconItem
                href="https://www.linkedin.com/company/kristiania-management/"
                icon={faLinkedinIn}
              />
              <IconItem
                href="https://www.facebook.com/Kristianiamanagement"
                icon={faFacebookF}
              />
              <IconItem
                href="https://www.facebook.com/Kristianiamanagement"
                icon={faInstagram}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const IconItem = ({ href, icon }) => (
  <li>
    <a className="icon" href={href} rel="noopener noreferrer">
      <span className="icon is-small">
        <FontAwesomeIcon icon={icon} />
      </span>
    </a>
  </li>
)

IconItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.object,
}

ContactUs.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  intl: PropTypes.object,
}

export default injectIntl(ContactUs)
