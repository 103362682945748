import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import ContactUs from '../components/ContactUs'
import { withIntl } from '../i18n'
import { FormattedMessage } from 'react-intl'

export const FrontPage = ({
  content,
  contentComponent,
  headerImg,
}) => {
  const PageContent = contentComponent || Content
  return (
    <div>
      <div id="front-page">
        <section
          id="barcode"
          className="hero is-primary is-large"
          style={{ backgroundImage: `url(${headerImg})` }}
        >
          <div className="hero-body">
            <div className="container" />
          </div>
        </section>
        <section
          id="registrer-cv"
          className="section is-medium has-background-primary has-text-white"
        >
          <div className="container">
            <div className="columns">
              <div className="column is-two-thirds-desktop">
                <PageContent className="content" content={content} />
              </div>
              <div className="column has-centered-content">
                <a
                  className="button is-secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://kristianiamanagement.recman.no/register.php"
                >
                  <FormattedMessage id="registerCV" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <ContactUs />
      </div>
    </div>
  )
}

FrontPage.propTypes = {
  title: PropTypes.string.isRequired,
  seoDescription: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  headerImg: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout
      seoDescription={post.frontmatter.seoDescription}
      seoTitle={post.frontmatter.seoTitle}
    >
      <FrontPage
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        headerImg={post.frontmatter.headerImg}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default withIntl(IndexPage)

export const pageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        seoDescription
        seoTitle
        headerImg
      }
    }
  }
`
